/* @flow */

import type { Product, ProductCardProduct } from "shop-state/types";
import type { Option } from "@crossroads/ui-components";

import React, { useState, useEffect, useContext } from "react";
import { AnalyticsContext } from "@crossroads/analytics";
import { useData } from "crustate/react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import {
  AutoExpand,
  productDefaults,
  ProductViewMedia,
  getAttributesConfigurable,
  getSelectedConfigurable,
} from "@crossroads/ui-components";
import { useGetProductMeta } from "helpers/get-meta";
import useFormat from "helpers/use-format";
import { QuoteData, CustomerData } from "data";
import { Link } from "react-router-dom";
import AddToCart from "components/AddToCart";
import Wrapper from "components/Wrapper";
import NotFoundView from "components/NotFoundView";
import ProductList from "components/ProductList";
import ProductCarousel from "components/ProductCarousel";
import { getSelectedPointsPrice } from "./get-price";
import Breadcrumbs from "components/Breadcrumbs";
import Button from "components/Button";
import { StoreInfoContext } from "entrypoint/shared";
import { isPointsOnly, usePointsLabel, useSpendingLimit } from "helpers/points";
import cn from "classnames";

import styles from "./styles.scss";

type ProductViewProps = {
  product: Product,
  location: {
    state: {
      breadcrumbLink: string,
      list: string,
      position: number,
    },
  },
};

type HintProductViewProps = {
  product: ProductCardProduct,
  location: {
    state: {
      breadcrumbLink: string,
      image: { x1: string, x2: string },
    },
  },
};

export const AddToCartBtn = ({
  loading = false, outOfStock, typeButton, btnAction, setRef,
}: { loading?: boolean,
  outOfStock: boolean,
  typeButton: boolean,
  btnAction?: () => void,
  setRef: any,
}) => {
  const t = useTranslate();
  const quoteState = useData(QuoteData);
  const addingToCart = quoteState.state === "ADDING_ITEM";

  return (
    <div ref={setRef}>
      <Button
        className={cn(styles.addToCart, "awardit-addToCartBtn")}
        variant="primary"
        loading={addingToCart || loading}
        type={!typeButton ? "submit" : "button"}
        onClick={() => btnAction && btnAction()}
      >
        {outOfStock ? t("PRODUCT.OUT_OF_STOCK") : t("PRODUCT.ADD_TO_CART")}
      </Button>
    </div>
  );
};

const ProductView = ({ product, location }: ProductViewProps) => {
  const t = useTranslate();
  const {
    routes,
    content: {
      productView,
      productView: { partialPaymentHide },
      productCarousel: { useOnProductView },
    },
  } = useContext(StoreInfoContext);
  const gaContext = useContext(AnalyticsContext);
  const customerState = useData(CustomerData);
  const quoteState = useData(QuoteData);
  const { formatPrice, formatPoints } = useFormat();
  const customer = customerState.data ? customerState.data : null;
  const quote = quoteState.data ? quoteState.data : null;
  const configAttributes = product.type === "configurable" ? getAttributesConfigurable(product) : {};
  const [selected, setSelected] = useState<Option>(productDefaults(product));
  const brand = product.attributes.manufacturer;
  const URIBrand = encodeURIComponent(brand);
  const meta = useGetProductMeta(product, t);
  const { pointsPrice } = getSelectedPointsPrice(product, selected);
  const label = usePointsLabel();
  const spendingLimit = useSpendingLimit();
  const memberTargetList = customer &&
    customer.memberTargetList &&
    customer.memberTargetList.list.length > 0 ?
    customer.memberTargetList.list :
    [];

  const [currentImage, setCurrentImage] = useState({
    smallImage: { x1: product.attributes.smallImage?.x1 || "", x2: product.attributes.smallImage?.x2 || "" },
    largeImage: { x1: product.attributes.largeImage?.x1 || "", x2: product.attributes.largeImage?.x2 || "" },
  });
  const allProductsViewTitle = routes.allProductsView && routes.allProductsView.title ? routes.allProductsView.title : "";
  const allProductsViewLink = routes.allProductsView && routes.allProductsView.url ? routes.allProductsView.url : "";
  const parentLink = { name: allProductsViewTitle, url: allProductsViewLink };
  const productValueShow = productView.productValueShow !== null &&
    productView.productValueShow !== undefined &&
    productView.productValueShow === true;

  const { campaignOriginalPrice } = product.attributes;

  const campaignDefaultPrice =
  campaignOriginalPrice !== null && campaignOriginalPrice !== undefined ?
    Number.parseInt(campaignOriginalPrice, 10) : 0;

  const hasReducedPrice = Boolean(campaignDefaultPrice !== 0 &&
    pointsPrice && pointsPrice.points.value.exVat < campaignDefaultPrice);

  const salePercent = (campaignDefaultPrice !== null && pointsPrice && pointsPrice.points) ?
    Math.round((1 - (pointsPrice.points.value.exVat / campaignDefaultPrice)) * 100) : null;

  // Change image when selecting option
  useEffect(() => {
    const selectedItem = getSelectedConfigurable(selected, configAttributes);

    if (selectedItem) {
      const a = selectedItem.product.attributes;

      if (a) {
        setCurrentImage({
          smallImage: { x1: a.smallImage?.x1 || "", x2: a.smallImage?.x2 || "" },
          largeImage: { x1: a.largeImage?.x1 || "", x2: a.largeImage?.x2 || "" },
        });
      }
    }
  }, [selected]);

  if (!customer || !quote) {
    return null;
  }

  const storeIsPointsOnly =
    (spendingLimit.minPercent === spendingLimit.maxPercent) &&
    (spendingLimit.minPercent === 100);
  const pointsOnlyProduct = isPointsOnly(pointsPrice);

  if (
    memberTargetList.length > 0 &&
    product.attributes.awarditTargetId &&
    !memberTargetList.includes(product.attributes.awarditTargetId)) {
    return (<NotFoundView />);
  }

  return (
    <Wrapper>
      <Helmet
        title={meta.title}
        meta={meta.data}
        link={meta.link}
      />

      <Breadcrumbs parentLink={parentLink} current={product.name} />

      <div className={styles.block}>
        <div className={styles.left}>
          <header className={cn(styles.header, "awardit-productViewHeader")}>
            <h1 className={styles.name}>{product.name}</h1>
            {routes.brandsView && routes.brandsView.url &&
              <Link
                to={{
                  state: { hint: { type: "brand", brand: { name: brand } } },
                  pathname: `${routes.brandsView.url}/${encodeURIComponent(brand)}`,
                }}
                className={styles.brand}
              >
                {brand}
              </Link>
            }
          </header>
          {hasReducedPrice && (
            <div className={styles.saleInfo}>
              <span className={styles.prevPrice}>{formatPoints(campaignDefaultPrice)}</span>
              <span className={styles.salePercent}>{salePercent !== null ? `-${salePercent}%` : ""}</span>
            </div>
          )}
          <AddToCart
            location={location}
            quote={quote}
            customer={customer}
            product={product}
            selected={selected}
            setSelected={setSelected}
            pointsPrice={pointsPrice}
            gaContext={gaContext}
            styles={styles}
          />

          <p style={{ marginTop: 0 }}>
            <a
              href="https://helpdesk.crossroads.se/?s=16061df9ac5db416061df9ac5df1"
              target="_blank"
              rel="noopener noreferrer"
            >Kontakta oss
            </a> om du inte hittar det du söker.
          </p>

          {(!storeIsPointsOnly && !pointsOnlyProduct && partialPaymentHide !== true) &&
            <div className={styles.insufficientFunds}>
              <h3>{productView.partialPaymentHeader ?? t("PRODUCT.INSUFFICIENT_FUNDS_HEADING")}</h3>
              <p>
                {productView.partialPaymentText ??
                t("PRODUCT.INSUFFICIENT_FUNDS_BODY", {
                  pointsLabel: label(),
                  spendingLimit: spendingLimit.minPercent,
                  currencyLimit: (100 - spendingLimit.minPercent),
                })}
              </p>
            </div>
          }

          <section>
            <h3 className={styles.descriptionHeading}>{t("PRODUCT.DESCRIPTION")}</h3>
            {/* eslint-disable react/no-danger */}
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: product.attributes.description }}
            />
            {/* eslint-enable react/no-danger */}

            {(productValueShow &&
              product.price.incVat !== undefined &&
              product.price.incVat !== null) &&
              <div className={styles.productValue}>
                <h4>
                  {t("PRODUCT.PERQUISITE_VALUE",
                    { value: formatPrice(Math.round(product.price.exVat)) }
                  )}
                </h4>
              </div>
            }

            {(product.attrDescriptions.manufacturer &&
              product.attrDescriptions.manufacturer.icon) &&
              <div className={styles.manufacturer}>
                {routes.brandsView && routes.brandsView.url &&
                  <Link to={`${routes.brandsView.url}/${URIBrand}`} className={styles.logo}>
                    <img
                      src={product.attrDescriptions.manufacturer.icon}
                      alt={`Logo ${brand}`}
                    />
                  </Link>
                }
                <p>{product.attrDescriptions.manufacturer.description}</p>
                {routes.brandsView && routes.brandsView.url &&
                  <Link to={`${routes.brandsView.url}/${encodeURIComponent(brand)}`} className={styles.goto}>
                    {t("PRODUCT.GO_TO_MANUFACTURER")}
                  </Link>
                }
              </div>
            }
          </section>

        </div>
        <div className={styles.right}>
          <ProductViewMedia
            alt={product.name}
            currentImage={currentImage}
            gallery={product.gallery}
            galleryPerRow={5}
            location={{ ...location }}
          />
        </div>
      </div>

      <AutoExpand className={styles.lists}>
        {/* Use top level category which is first in category object */}
        {Boolean(useOnProductView) && product.categories.length > 0 && (
          <ProductCarousel variant="PRODUCT" category={product.categories[0].name} sku={product.sku} />
        )}

        &nbsp;
        <div className={styles.relatedList}>
          {product.relatedProducts.items.length > 0 &&
          <ProductList
            heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
            products={product.relatedProducts.items}
            productsPerRow={2}
            listName={t("PRODUCT.OTHERS_ALSO_LIKED")}
          />
          }
        </div>

        <div className={styles.historyList}>
          {product.crossSellProducts.items.length > 0 &&
          <ProductList
            heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
            products={product.crossSellProducts.items}
            productsPerRow={2}
            listName={t("PRODUCT.OTHERS_ALSO_LIKED")}
          />
          }
        </div>
      </AutoExpand>
    </Wrapper>
  );
};

export const Hint = ({ product, location }: HintProductViewProps) => {
  const t = useTranslate();
  const brand = product.attributes.manufacturer;
  const { routes } = useContext(StoreInfoContext);
  const image = product.attributes.largeImage ||
    product.attributes.smallImage ||
    location.state.image || {};
  const allProductsViewTitle = routes.allProductsView && routes.allProductsView.title ? routes.allProductsView.title : "";
  const allProductsViewLink = routes.allProductsView && routes.allProductsView.url ? routes.allProductsView.url : "";
  const parentLink = { name: allProductsViewTitle, url: allProductsViewLink };

  return (
    <Wrapper>
      <Breadcrumbs parentLink={parentLink} current={product.name} />

      <div className={styles.block}>
        <div className={styles.left}>
          <header className={styles.header}>
            <h1 className={styles.name}>{product.name}</h1>
            {routes.brandsView && routes.brandsView.url &&
              <Link to={`${routes.brandsView.url}/${brand}`} className={styles.brand}>
                {brand}
              </Link>
            }
          </header>

          <section>
            <h3 className={styles.descriptionHeading}>{t("PRODUCT.DESCRIPTION")}</h3>
            {/* eslint-disable react/no-danger */}
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: product.attributes.description }}
            />
            {/* eslint-enable react/no-danger */}
          </section>
        </div>
        <div className={styles.right}>
          <ProductViewMedia
            alt={product.name}
            currentImage={{
              largeImage: image,
              smallImage: image,
            }}
            gallery={[]}
            galleryPerRow={5}
            location={{ ...location }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export const HintProductView = withRouter(Hint);

export default withRouter(ProductView);
